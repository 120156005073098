import request from '@/plugin/axios'
// 列表
export function list(params) {
  return request({
    url: '/sys/sysDept/list',
    method: 'get',
    params
  })
}

// 列表
export function select(params) {
  return request({
    url: '/sys/sysDept/select',
    method: 'get',
    params
  })
}

// 排序
export function updateSort(params,type) {
  return request({
    url: `/sys/sysDept/${type=='up' ? 'updateSortUp' : 'updateSortDown'}`,
    method: 'get',
    params
  })
}

// 保存
export function save(data) {
  return request({
    url: `/sys/sysDept/${!data.get('id')  ? 'add' : 'update'}`,
    method: 'post',
    data
  })
}

// 详细
export function info(params) {
  return request({
    url: '/sys/sysDept/info/'+params.id,
    method: 'get',
    params
  })
}


// 删除
export function deleted(data) {
  return request({
    url: '/sys/sysDept/deleted',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}
