/**
 * Created by zhengguorong on 2016/11/21.
 */
export default class Element {
  constructor (ele = {}) {
    this.id = ele.id
    this.type = ele.type || 'pic'
    this.imgSrc = ele.imgSrc || ''
    this.goodsId = ele.goodsId || ''
    this.setId = ele.setId || ''
    this.unitId = ele.unitId || ''
    this.unitName = ele.unitName || ''
    this.name = ele.name || '请选择'
    this.typeName = ele.typeName || '类型'
    this.number = ele.number || '01'
    this.salePrice = ele.salePrice || ''
    this.style = ele.style || 1
    this.positionLeft = ele.positionLeft || 100
    this.positionTop = ele.positionTop || 100
    this.btnTop = ele.btnTop || 100
    this.btnLeft = ele.btnLeft || 100
    this.fontTop = ele.fontTop || 100
    this.fontLeft = ele.fontLeft || 100
    this.btnType = ele.btnType || 'right'
    this.btnStyle = ele.btnStyle || 'red'
    this.btnBgColor = ele.btnBgColor || '#000000'
    this.btnColor = ele.btnColor || '#FFFFFF'
    this.numberShow = ele.numberShow ||  0
    this.numberBgColor = ele.numberBgColor || '#000000'
    this.numberFontColor = ele.numberFontColor || '#FFFFFF'
    this.width = ele.width || 100
    this.height = ele.height || 100
    this.lineHeight = ele.lineHeight || 0
    this.fontSize = ele.fontSize || 18
    this.fontColor = ele.fontColor || '#FFFFFF'
    this.zindex = ele.zindex || 1
  }
}
