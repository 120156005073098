<template>

    <el-popover placement="bottom-end" width="80" trigger="hover" class="d2-mr" style="padding-left:10px;border-left:0 solid #aaa">
      <div class="whole">
        <div class="menu">
          <el-row :gutter="20">
            <el-col :span="24">
              <div @click.stop="goPage('sys/setting?type=1')">
                <el-link  icon="el-icon-setting" :underline="false">个人中心</el-link>
              </div>
            </el-col>
            <el-col :span="24">
              <div @click.stop="goPage('sys/setting?type=2')">
                <el-link  icon="el-icon-lock" :underline="false">修改密码</el-link>
              </div>
            </el-col>
            <el-col :span="24">
              <div @click.stop="goPage('frontEnd/log')">
                <el-link  icon="el-icon-warning-outline" :underline="false">错误日志</el-link>
              </div>
            </el-col>
            <el-col :span="24">
              <div @click.stop="goPage('sys/loginHistory')">
                <el-link  icon="el-icon-location-outline" :underline="false">上次登录</el-link>
              </div>
            </el-col>
            <el-col :span="24" v-if="isAuth('sys:sysCommon:clearCache')">
              <div @click.stop="clearCache">
                <el-link  icon="el-icon-location-outline" :underline="false">清除缓存</el-link>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="action" @click="logOff">
          <el-link  icon="el-icon-switch-button" :underline="false">退出登录</el-link>
        </div>
      </div>

      <div slot="reference" style="cursor:pointer;position: relative;height: 30px;line-height: 30px">
        <div style="position: relative" >
          <label style="position: absolute;top:0;">
            <el-avatar shape="square" :size="30" :src="userInfo.avatarUrl?userInfo.avatarUrl:avatarDefaultImg"/>
          </label>
          <label  class="user-name-txt" >
            <!-- 用户名:${userInfo.userName}工号:${userInfo.empNo}` -->
            {{userInfo.userName ? `${userInfo.userName}` : '未登录'}}
          </label>
        </div>
      </div>

    </el-popover>



</template>

<script>
import * as api from '@api/sys/sysCommon'
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('d2admin/user', [
      'userInfo'
    ])
  },
  data(){
    return {
      avatarDefaultImg:require("@/assets/images/avatar.png"),
    }
  },
  methods: {
    ...mapActions('d2admin/account', [
      'logout'
    ]),
    goPage (path) {
      if (this.$router.app._route.fullPath === '/' + path) {
        this.$message({
          message: '已在当前页面！',
          type: 'warning'
        })
        return
      }
      this.$router.push('/' + path)
    },
    /**
     * @description 登出
     */
    logOff () {
      this.logout({
        confirm: true
      })
    },
    clearCache () {
      api.clearCache().then(res => {
        if (res && res.code === 200) {
          this.$message.success('清理完成')
        } else {
          this.$message.error(res.msg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar {
    display: inline-block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #ffffff;
    vertical-align: middle;
  }
  .user-name-txt {
    margin-left: 40px;
    margin-right: 5px;
    color: #505460;
  }
  .company-name-text {
    margin-left: 10px;
    margin-right: 10px;
    color: #505460;
  }
  .whole {
    background: #ffffff;
    .menu {
      .el-col {
        div {
          width: 100%;
          text-align: center;
          cursor: pointer;
          box-sizing: border-box;
          padding: 5px;
          padding-bottom: 20px;
        }
      }
      .toManager {
        padding: 15px 10px;
        .el-button {
          width: 100%;
          i {
            font-size: 12px;
          }
        }
      }
    }
    .action {
      text-align: center;
      color: #606266;
      position: relative;
      padding-top: 12px;
      cursor: pointer;
      &:before {
        content: "";
        left: -12px;
        top: 0;
        position: absolute;
        width: calc(100% + 24px);
        height: 1px;
        background: #eee;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
