<template>
  <div class="D2Container-card">
    <div v-if="$slots.header" class="D2Container-card__header" ref="header">
      <slot name="header"/>
    </div>
    <div class="D2Container-card__body" ref="body">
      <div class="D2Container-card__body-card">
        <slot/>
      </div>
    </div>
    <div v-if="$slots.footer" class="D2Container-card__footer" ref="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import scroll from './mixins/normal'
export default {
  name: 'D2Container-card',
  mixins: [
    scroll
  ],
  mounted () {
    // 增加滚动事件监听
    this.addScrollListener()
  },
  beforeDestroy () {
    // 移除滚动事件监听
    this.removeScrollListener()
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/D2Container/components/D2ContainerCard.vue"
}
</vue-filename-injector>
