/**
 * Created by zhengguorong on 2016/11/24.
 */
export default class Theme {
  constructor (theme = {}) {
    this.title = theme.title || '标题'
    this.uploading = theme.uploading || false
    this.description = theme.description || '描述'
    this.pages = theme.pages || []
    this.id = theme.id || ''
    this.type = theme.type || 'h5'
    this.canvasHeight = theme.canvasHeight/2 || 768
    this.canvasWidth = theme.canvasWidth/2 || 1024
    this.deviceName = theme.deviceName || ''
    this.canvasTypeName = theme.canvasTypeName || ''
  }
}
