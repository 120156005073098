import request from '@/plugin/axios'

// 获取菜单
export function nav(params) {
  return request({
    url: '/sys/menu/nav',
    method: 'get',
    params
  })
}

// 列表
export function list(params) {
  return request({
    url: '/sys/menu/list',
    method: 'get',
    params
  })
}
// 排序
export function updateSort(params,type) {
  return request({
    url: `/sys/menu/${type=='up' ? 'updateSortUp' : 'updateSortDown'}`,
    method: 'get',
    params
  })
}

// 列表
export function getRoleUserMenuList() {
  return request({
    url: '/sys/menu/getRoleUserMenuList',
    method: 'get'
  })
}

// 列表
export function select(params) {
  return request({
    url: '/sys/menu/select',
    method: 'get',
    params
  })
}


// 保存
export function save(data) {
  return request({
    url: '/sys/menu/save',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}

// 详细
export function info(params) {
  return request({
    url: '/sys/menu/info/'+params.id,
    method: 'get',
  })
}


// 删除
export function deleted(data) {
  return request({
    url: '/sys/menu/deleted',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}
