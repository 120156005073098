<template>
  <div style="border-left:0 solid #aaa;">
    <el-popover
      placement="top"
      width="400"
      height="500"
      trigger="click">
      <div class="popover-table">
        <el-table :data="brandList" @select="(val)=>{this.changeBrand(val) }" :row-style="{height: '50px'}" :cell-style="{padding: '5px 0'}">
          <el-table-column width="100" property="name" label="品牌名称"></el-table-column>
          <el-table-column  align="center" min-width="160">
            <template slot="header"> 操作</template>
            <template slot-scope="scope">
              <el-button @click.stop="changeBrand(scope.row.id,scope.row.name)" round>选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      

      <el-button slot="reference" class="buttom-stya">
        <span> {{ userInfo.brandName }} <i class="el-icon-caret-bottom"></i></span>
      </el-button>
    </el-popover>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import * as api from '@/api/sys/sysUser'
import * as apiBmsBrand from '@/api/bms/bmsBrand'
import util from "@/libs/util";

export default {
  inject: ['reload'],
  computed: {
    ...mapState('d2admin/user', [
      'userInfo'
    ])
  },
  mounted() {
    this.initBrandList()
  },
  components: {},
  data() {
    return {
      brandList: []
    }
  },
  methods: {
    initBrandList() {
      apiBmsBrand.listOptions().then(res => {
        if (res && res.code === 200) {
          this.brandList = res.list
        }
      })
    },
    changeBrand(brandId, brandName) {
      api.changeBrand({brandId: brandId}).then(res => {
        if (res && res.code === 200) {
          this.userInfo.brandId = brandId;
          this.userInfo.brandName = brandName;
          util.cookies.set('brandId', brandId)
          this.$store.dispatch('d2admin/user/set', this.userInfo, {root: true})
          this.reload()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.popover-table{
 width: 100%;
  height: 500px !important;
  overflow:auto;overflow-y:scroll
}
.company-name-text {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: #FFFFFF !important;

}
.buttom-stya{
    border-radius: 0 !important;
    border-radius: 0 4px  4px 0 !important;
    height: 29px !important;
    margin-top: 2px !important;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-brand/index.vue"
}
</vue-filename-injector>
