<!--
 * @Description: selectDept
 * @Author: juguangxing
 * @Date: 2020年02月20日16:27:16
 * @LastEditTime:
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-select v-model="selectKey"  @change="onSelect" filterable clearable placeholder="请选择部门">
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>
<script>
  import * as api from '@/api/sys/sysDept';
  export default {
    name: 'selectDept',
    props: ['value','shopId'],
    data () {
      return {
        selectKey:'',
        dataList:[],
      }
    },
    watch: {
      value: {
        handler(val){
          this.selectKey = val
        },
        immediate: true
      }
    },
    mounted () {
      this.getList()
    },
    methods: {
      onSelect(item){
        this.$emit('select',item)
      },
      // 获取列表
      getList () {
        api.select().then(res => {
          this.dataList = res && res.code === 200 ? res.list : []
        })
      }
    }
  }
</script>
<style scoped>

</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/SelectSysDept/index.vue"
}
</vue-filename-injector>
