import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import d2admin from './modules/d2admin'
import editor from './editor'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    d2admin,
    editor,
  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore (state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    }
  },
})
