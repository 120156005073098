import request from '@/plugin/axios'
// 列表
export function select(params) {
  return request({
    url: '/sys/sysUserModuleFields/select',
    method: 'get',
    params
  })
}

// 保存
export function save(data) {
  return request({
    url: '/sys/sysUserModuleFields/save',
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
}


// 排序
export function updateSort(data) {
  let formJson  = JSON.stringify(data)
  return request({
    url: '/sys/sysUserModuleFields/updateSort',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data:formJson
  })
}



// 详细
export function info(params) {
  return request({
    url: '/sys/sysUserModuleFields/info/'+params.id,
    method: 'get',
    params
  })
}
// 回复默认
export function retrospective(data) {
  return request({
    url: '/sys/sysUserModuleFields/restoreSetting',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}
