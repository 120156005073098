export const SET_CUR_EDITOR_ELEMENT = 'SET_CUR_EDITOR_ELEMENT' // 设置当前编辑的h5元素
export const ADD_PIC_ELEMENT = 'ADD_PIC_ELEMENT' // 添加图片元素
export const ADD_GOODS_ELEMENT = 'ADD_GOODS_ELEMENT' // 添加菜品元素
export const PLAY_ANIMATE = 'PLAY_ANIMATE' // 播放动画
export const STOP_ANIMATE = 'STOP_ANIMATE' // 播放动画
export const ADD_PAGE = 'ADD_PAGE' // 添加页面
export const DELETE_PAGE = 'DELETE_PAGE' // 删除页面
export const SET_CUR_EDITOR_PAGE = 'SET_CUR_EDITOR_PAGE' // 设置当前编辑的页面
export const UPDATE_SORT = 'UPDATE_SORT' // 设置当前编辑的页面
export const GET_USER_THEME_LIST = 'GET_USER_THEME_LIST' // 获取用户h5列表
export const SET_CUR_EDITOR_THEME = 'SET_CUR_EDITOR_THEME' // 设置当前编辑h5页面
export const DELETE_ELEMENT = 'DELETE_ELEMENT' // 删除元素
export const CREATE_THEME = 'CREATE_THEME' // 创建主题
export const UPDATE_THEME_DES = 'UPDATE_THEME_DES' // 更新主题描述
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS' // 更新数据成功
export const ADD_THEME_SUCCESS = 'ADD_THEME_SUCCESS' // 新增页面数据成功
export const SAVE_PIC = 'SAVE_PIC' // 保存图片
export const COPY_PAGE = 'COPY_PAGE' // 复制页面
export const GET_PAGE_THEMEID = 'GET_PAGE_THEMEID' // 根据主题ID获取页面
export const CLEAN_BG = 'CLEAN_BG' // 清除背景图
export const CLEAN_ELE = 'CLEAN_ELE' // 清除元素
export const FETCH_PIC_LIST = 'FETCH_PIC_LIST' // 获取图片列表
export const FETCH_VIDEO_LIST = 'FETCH_VIDEO_LIST' // 获取视频列表



// 加
export const SET_PAGE_BG_IMG = 'SET_PAGE_BG_IMG' // 设置背景图
export const REMOVE_PAGE_BG_IMG = 'REMOVE_PAGE_BG_IMG' // 删除背景
export const SET_PAGE_CATEGORY = 'SET_PAGE_CATEGORY' //设置页面分类
export const SET_PAGE_TYPE = 'SET_PAGE_TYPE' //设置页面类型
export const SET_PAGE_NAME = 'SET_PAGE_NAME' //设置页面类型
export const SET_UPLOADING_THEME = 'SET_UPLOADING_THEME' //设置主题上传



export const PUSH_PIC_LIST = 'PUSH_PIC_LIST' // 图片列表
export const PUSH_VIDEO_LIST = 'PUSH_VIDEO_LIST' // 视频列表
export const CLEAN_PIC_LIST = 'CLEAN_PIC_LIST' // 清除图片列表
export const DELETE_THEME = 'DELETE_THEME' // 删除主题
export const SORTELEMENTS_BY_ZINDEX = 'SORTELEMENTS_BY_ZINDEX' // 元素重新排序
