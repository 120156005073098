import Vue from 'vue'
import VueRouter from 'vue-router'
import * as api from '@/api/sys/sysMenu'
import layoutHeaderAside from '@/layout/header-aside'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { isURL } from '@/utils/validate'
import { treeMenuTranslate } from '@/utils'
import store from '@/store/index'
import util from '@/libs/util.js'

// 路由数据
// import routes from './routes'


// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)


// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  // gmsPicMenu
  {
    path: '/gms/gmsPicMenu/pages',
    name: 'gmsPicMenuEditor',
    component: _import('gms/gmsPicMenu/pages'),
  },
  // gmsPicMenu
  {
    path: '/gms/gmsPicMenu/theme',
    name: 'gmsPicMenuTheme',
    component: _import('gms/gmsPicMenu/theme'),
  },
  // 404
  {
    path: '/error/404',
    name: '404',
    component: _import('system/error/404'),
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: _import('sys/sysLogin')
  },
  //隐私政策
  {
    path: '/privacy',
    name: 'privacy',
    component: _import('pfs/privacy'),
  }
  // H5付款界面
  // {
  //   path: '/payDeposit',
  //   name: 'payDeposit',
  //   component: _import('pay/payDeposit'),
  // }
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  name: 'main',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    // 首页
    {
      path: 'index',
      name: 'index',
      meta: {
        title: '首页',
      },
      component: _import('index')
    },
    // 个人设置
    {
      path: 'sys/setting',
      name: 'sysSetting',
      meta: {
        auth: true
      },
      component: _import('sys/setting')
    },
    // 操作记录
    {
      path: 'sys/loginHistory',
      name: 'sysLoginHistory',
      meta: {
        auth: true
      },
      component: _import('sys/sysLoginHistory')
    },
    // 错误日志
    {
      path: 'frontEnd/log',
      name: 'sysLog',
      meta: {
        auth: true
      },
      component: _import('system/log')
    },
    // 刷新页面 必须保留
    {
      path: 'refresh',
      name: 'refresh',
      hidden: true,
      component: _import('system/function/refresh')
    },
    // 页面重定向 必须保留
    {
      path: 'redirect/:route*',
      name: 'redirect',
      hidden: true,
      component: _import('system/function/redirect')
    }
  ],
  beforeEnter (to, from, next) {
    let token = util.cookies.get('token')
    if (!token || !/\S/.test(token)) {
      next({ name: 'login' })
    }
    next()
  }
}



// 导出路由 在 main.js 里使用
const router = new VueRouter({
  //mode: 'hash',
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
})




/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {

  // 进度条
  NProgress.start()
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  if (!router.options.isAddDynamicMenuRoutes || !fnCurrentRouteType(to, globalRoutes) === 'global') {
    router.options.isAddDynamicMenuRoutes = true
    api.nav().then(res => {
      if (res && res.code === 200) {

        fnAddDynamicMenuRoutes(res.data.routeList)
        let menuAside = [{
          path: '/index',
          title: '首页',
          iconSvg: 'home',
        }];
        menuAside = menuAside.concat(res.data.menuList)
        store.commit('d2admin/menu/asideSet', menuAside)
        sessionStorage.setItem('menuList', JSON.stringify(res.data.menuList || '[]'))
        sessionStorage.setItem('permissions', JSON.stringify(res.data.permissions || '[]'))
      } else {
        sessionStorage.setItem('menuList', '[]')
        sessionStorage.setItem('permissions', '[]')
      }

    }).catch((e) => {
      router.options.isAddDynamicMenuRoutes = false
      //console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
      //this.$message.error("请求菜单列表和权限失败，跳转至登录页！");
       if(e.message.indexOf("token失效") != -1){
         router.push({name: 'login'})
         return;
       }
       //console.log(`%c${e} `, 'color:blue')
       next({name: 'login',redirect:true})
      //router.push({name: 'login'})
    })
  }

  // 确认已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
  await store.dispatch('d2admin/page/isLoaded')
  // 确认已经加载组件尺寸设置 https://github.com/d2-projects/d2-admin/issues/198
  await store.dispatch('d2admin/size/isLoaded')
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some(r => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const token = util.cookies.get('token')
    if (token && token !== 'undefined') {
      next()
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
      // https://github.com/d2-projects/d2-admin/issues/138
      NProgress.done()
    }
  } else {
    // 不需要身份校验 直接通过
    next()
  }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType (route, globalRoutes = []) {
  var temp = []
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}


/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    try {
      if (menuList[i].list && menuList[i].list.length >= 1) {
        temp = temp.concat(menuList[i].list)
      } else if (menuList[i].path && /\S/.test(menuList[i].path)) {
        menuList[i].path = menuList[i].path.replace(/^\//, '')
        var route = {
          // path: menuList[i].url.replace('/', '-'),
          path: menuList[i].path,
          component: _import(menuList[i].comPath),
          name: menuList[i].perms,
          meta: {
            menuId: menuList[i].id,
            title: menuList[i].title,
            cache: true,
            auth: true
          }
        }
        // console.log("route",route);
        // url以http[s]://开头, 通过iframe展示
        if (!isURL(menuList[i].path)) {
          try {
            route['component'] = _import(`${menuList[i].comPath}`) || null
          } catch (e) {
            // console.log("路由获取错误1:",err);
          }
        }
        routes.push(route)
      }

    } catch(err) {
      // console.log("路由获取错误2:",err);
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes)
  } else {
    mainRoutes.children = routes
    router.addRoutes([
      mainRoutes,
      { path: '*', redirect: { name: '404' } }
    ])
    store.commit('d2admin/page/init', routes)
    // 设置侧边栏菜单
    ////console.log('\n')
    ////console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
    ////console.log(mainRoutes.children)
    ////console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
  }
}


router.afterEach(to => {
  // 进度条
  NProgress.done()
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to)
  // 更改标题
  util.title(to.meta.title)
})

export default router
