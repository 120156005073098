<template>
  <el-drawer title="员工列表" class="addEdit" direction="rtl" :visible.sync="drawer" size="70%" :before-close="hidePanel">
    <div class="tab">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane v-if="isAuth('sys:sysUser:add')" label="用户管理" name="activeUserModule">
          <user-add-or-update v-model="userDrawerForm" ref="userDrawerForm" :formType="formType"
                              @cancelEvent="hidePanel"/>
        </el-tab-pane>
        <el-tab-pane v-if="isAuth('bsm:bsmConfigCommission:add')&& userId!==null" label="预订提成"
                     name="activeCommissionModule">
          <config-commission v-model="commissionDrawerForm" ref="refConfigCommission" @cancelEvent="hidePanel"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>
<script>
import userAddOrUpdate from './addOrUpdate';
import configCommission from './../../bsm/bsmConfigCommission/addOrUpdate';

export default {
  props: ['value', 'userId', 'formType'],
  components: {userAddOrUpdate, configCommission},
  data() {
    return {
      dataForm: {},
      activeName: 'activeUserModule',
      drawer: false,
      userDrawerForm: false,
      commissionDrawerForm: false
    }
  },
  watch: {
    value(val) {
      this.drawer = val
      this.userDrawerForm = val;
      this.commissionDrawerForm = val;
    },
    drawer(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    hidePanel() {
      this.drawer = false;
      this.userDrawerForm = false;
      this.commissionDrawerForm = false;
      this.$emit('refParent');
    },
    init(id) {
      this.dataForm.id = id;
      this.$nextTick(() => {
        this.$refs.userDrawerForm.init(id);
      })
    },

    handleClick() {
      if (this.activeName === 'activeUserModule') {
        this.userDrawerForm = true;
        this.$nextTick(() => {
          this.$refs.userDrawerForm.init(this.userId);
        })
      }
      if (this.activeName === 'activeCommissionModule') {
        this.commissionDrawerForm = true;
        this.$nextTick(() => {
          this.$refs.refConfigCommission.init(this.userId)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
>>> .el-drawer__header {
  padding: 0;
  margin-bottom: 0;
  height: 0;
}

>>> .el-drawer__header .el-drawer__close-btn {
  position: relative;
  top: 30px;
  right: 15px;
  z-index: 10;
}

.tab {
  height: 100%;
  overflow: auto;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/sys/sysUser/openTab.vue"
}
</vue-filename-injector>
