// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
import D2Crud from '@d2-projects/d2-crud'
// store
import store from '@/store/index'
import request from '@/plugin/axios'
import VideoPlayer from 'vue-video-player'

import VCharts from 'v-charts'

import 'default-passive-events'

// 菜单和路由设置
import router from './router'
import menuHeader from '@/menu/header'
// 这里可以设置动态菜单和路由
import menuAside from '@/menu/aside'
/*import {
  frameInRoutes
} from '@/router/routes'*/

// 认证权限
import { isAuth } from '@/utils'

//百度地图
import BaiduMap from 'vue-baidu-map'

// 日期工具
import moment from 'moment'
// 核心插件
Vue.use(VideoPlayer)
Vue.use(VCharts)
Vue.use(d2Admin)
Vue.use(D2Crud)
Vue.use(BaiduMap,{
  ak:'ZQdUPUOYgGE69T0k0GAfRGGPu5O2av7h'
})
Vue.prototype.$http = request // ajax请求方法
Vue.prototype.isAuth = isAuth     // 权限方法
Vue.prototype.$moment = moment //日期工具
Vue.prototype.$pageSizes = [30,50,100,200,500,1000] //分页显示
Vue.prototype.$pageBigSizes =[100,200,500,1000,3000,6000,8000,10000] //分页显示
Vue.prototype.$pageBigSizes2 =[5000,10000,100000,200000] //分页显示
Vue.prototype.$reportDateRegionSize = 60  // 报表时间区间大小
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // 处理路由 得到每一级的路由设置
    //this.$store.commit('d2admin/page/init', frameInRoutes)
    // 设置顶栏菜单
    this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // 设置侧边栏菜单
    this.$store.commit('d2admin/menu/asideSet', menuAside)
    // 初始化菜单搜索功能
    this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  },
  watch: {
    // 检测路由变化切换侧边栏内容
   /* '$route.matched': {
      handler (matched) {
        if (matched.length > 0) {
          const _side = menuAside.filter(menu => menu.path === matched[0].path)
          this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : [])
        }
      },
      immediate: true
    }*/
  }
}).$mount('#app')
