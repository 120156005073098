import request from '@/plugin/axios'

// export function login(data) {
//   return request({
//     url: 'sys/login',
//     headers:{
//       'Content-Type': 'application/json'
//     } ,
//     method: 'post',
//     data
//   })
// }

export function login(data) {
  return request({
    url: 'sys/web/login',
    headers:{
      'Content-Type': 'application/json'
    } ,
    method: 'post',
    data
  })
}

// 添加超管
export function addSuperManage(data) {
  return request({
    url: '/sys/user/addSuperManage',
    method: 'post',
    data
  })
}

// 添加超管
export function listSuperAdmin(params) {
  return request({
    url: '/sys/user/listSuperAdmin',
    method: 'get',
    params
  })
}

export function changeBrand(data) {
  return request({
    url: 'sys/user/changeBrand',
    method: 'post',
    data
  })
}


export function userList(params) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params
  })
}

export function userListSimple(params) {
  return request({
    url: '/sys/user/listSimple',
    method: 'get',
    params
  })
}

// 获取自定义筛选设置的value
export function getValueList(params) {
  return request({
    headers:{
      'Content-Type': 'application/json'
    } ,
    url: '/sys/userModuleQuery/moduleList',
    method: 'get',
    params
  })
}



// 列表
export function list(params) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params
  })
}

// 保存
export function save(data) {
  return request({
    url: `/sys/user/${!data.get('id')  ? 'add' : 'update'}`,
    method: 'post',
    data
  })
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: '/sys/user/updatePassword',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}
// 修改密码
export function rePassword(data) {
  return request({
    url: '/sys/user/rePassword',
    method: 'post',
    data
  })
}

// 修改个人信息
export function updateUser(data) {
  return request({
    url: '/sys/user/updateUser',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}

// 详细
export function info(params) {
  return request({
    url: '/sys/user/info/'+params.id,
    method: 'get',
  })
}

// 获取个人信息
export function getInfo(params) {
  return request({
    url: 'sys/user/getInfo',
    method: 'get',
    params
  })
}

// 删除
export function deleted(data) {
  return request({
    url: '/sys/user/deleted',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}


// 授权保存
export function batchAuthorize(data) {
  return request({
    url: '/sys/user/batchAuthorize',
    method: 'post',
    // headers:{
    //   'Accept-Language':'zh',
    //   'Content-Type': 'application/json'
    // },
    data
  })
}
// 列表
export function listSimple(params) {
  return request({
    url: '/sys/user/listSimple',
    method: 'get',
    params
  })
}