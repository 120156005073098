import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
  let permissionsList = JSON.parse(sessionStorage.getItem('permissions') || '[]');
  let flag = false;
  permissionsList.map((item) => {
    if (item && item.perms == key) {
       flag = true;
    }
  });
  return flag;
}


/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data,level, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      if (!temp[data[k][pid]]['currentName']) {
        temp[data[k][pid]]['currentName'] = ''
      }
      if(!level || temp[data[k][pid]].level <  level){
        data[k]['_level'] = temp[data[k][pid]]._level + 1
        temp[data[k][pid]]['children'].push(data[k])
        data[k]['currentName'] = temp[data[k][pid]].currentName + data[k].name + ">";
      }
    } else {
      if (!data[k]['currentName']) {
        data[k]['currentName'] = ''
      }
      data[k]['currentName'] =   data[k].name + ">";
      res.push(data[k])
    }
  }
  return res
}



/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeRoleMenuTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDisabledRoleMenuTranslate (data,enabledIds, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
    let dataIn = enabledIds.indexOf(data[k][id]) != -1;
    if(dataIn){
      data[k]['disabled'] = false;
    }else{
      data[k]['disabled'] = true;
    }
  }
  return res
}





//设置选中树  menuIds :后台返回的treeNode
export function setCheckedKeys(menuIds, treeData) {
  var menuArr = [];
  if(menuIds){
    for (let j = 0; j < menuIds.length; j++) {
      const menuid = menuIds[j];
      let key = getNodekeys(menuid, treeData);
      if (key != '') {
        menuArr.push(key);
      }
    }
  }
  return menuArr;
}


// 设置禁用菜单
export function getDisabledDisAbleMenuIds(menuIds, allMenus) {
  let menuArr = [];
  if(menuIds){
    for (let j = 0; j < menuIds.length; j++) {
      const menuId = menuIds[j];
      let key = getNodekeys(menuId, allMenus);
      if (key != '') {
        menuArr.push(key);
      }
    }
  }
  return menuArr;
}




//获取选中的孩子节点
export function getNodekeys(menuId, childrens) {
  var key = '';
  for (let i = 0; i < childrens.length; i++) {
    const menu = childrens[i];
    let id = menu.id;
    let childs = menu.children;
    if (id == menuId) {//如果相等
      if (childs && childs.length > 0) {
        key = getNodekeys(menuId, childs);
      } else {  //没有孩子节点
        return menuId;
      }
    } else if (childs && childs.length > 0) {
      key =  getNodekeys(menuId, childs);
    }
    if (key != '') {
      return key;
    }
  }
  return key;
}



/**
 * 菜单树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeMenuTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      data[k].path = '/' + data[k].path
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}
//对象转formdata格式
export function objToFormData(params) {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if(params[key] || params[key] == 0 ){
      formData.append(key, params[key]);
    }
  });
  return formData;
}
//对象转formdata格式
export function convertOptions(optionList,groupList,optionId,groupId='id') {
  if (optionList.length > 0) {
    for (var group of groupList) {
      const source = {options: []}
      group = Object.assign(group, source)
      for (var option of optionList) {
        if (group[groupId] === option[optionId]) {
          group.options.push(option)
        }
      }
    }
  }
  return groupList;
}



/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  util.cookies.remove('token')
  util.cookies.remove('uuid')
  router.options.isAddDynamicMenuRoutes = false
}

/**
 * 获取天数
 */
export function getDaysOptions () {
  let days = [];
  console.log("getDaysOptions")
  let i;
  for (i = 1; i <= 31; i++) {
    days.push( {
      value: i,
      label: i
    });
  }
  return days
}

/**
 * 转换MomentLocal的国际化
 */
export function getConvertMomentLocal (local) {
  let localStr = ''
  switch (local) {
    case 'zh-chs': localStr = 'zh-cn'; break
    case 'zh-cht': localStr = 'zh-hk'; break
    default: localStr = local ; break
  }
  return localStr
}


// 导出Excel公用方法
export function exportMethod(data) {
  axios({
      method: data.method,
      url: data.url,
      // url: `${data.url}${data.params ? '?' + data.params : ''}`,
      responseType: 'blob',
      timeout: 60 * 20 * 1000,
      data:data.params

  }).then((res) => {
      const link = document.createElement('a')
      let blob = new Blob([res], {type: 'pplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = data.fileName //下载的文件名
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(link.href); // 释放URL 对象
      document.body.removeChild(link)
      return false
      
  }).catch(error => {
      this.$Notice.error({
          title: '错误',
          desc: '网络连接错误'
      })
      return false
  })
}


// 导出Excel公用方法
export function downLoadZip(data) {
 return  axios({
      method: data.method,
      url: data.url,
      responseType: 'blob',
      timeout: 60 * 20 * 1000,
      data:data.params
  })
}

export function getToday(hasD) {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
      month = "0" + month;
  }
  if (day < 10) {
      day = "0" + day;
  }
  let nowDate = year + "-" + month + "-" + day;
  if(hasD) {
    return {
      todayStart:nowDate + ' 00:00:00',
      todayEnd:nowDate + ' 23:59:59',
      month:year + "-" + month,
    }
  }else {
    return {
      todayStart:nowDate,
      todayEnd:nowDate,
      month:year + "-" + month,
    }
  }
}
//时间选择器限制时间在30天内的公共方法
export function pickerOptions() {
  return{
    shortcuts: [{
      text: '当天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.getTime() > Date.now() - 8.64e6
        picker.$emit('pick', [start, end]);
      }
    }, {
      
      text: '昨天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '前一周',
      onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
      }
    },{
      
      text: '前一月',
      onClick(picker) {
        console.log(222)
        var nowdays = new Date();
            var year = nowdays.getFullYear();
            var month = nowdays.getMonth();
            if(month==0){
                month = 12;
                year = year-1;
            }

            if(month<10){
              month = "0"+month;
            }
            var myDate = new Date(year,month,0);
 
            var start = year+'-'+month+'-01'; //上个月第一天
            var end = year+'-'+month+'-'+myDate.getDate();//上个月最后一天
        picker.$emit('pick', [start, end]);
        console.log(start,end,'endendendendend')
      }
    },
    {
      text: '本月',
      onClick(picker) {
          var now = new Date();
          var year = now.getFullYear();
          var month = now.getMonth()+1;
          if(month<10){
              month = '0'+month;
          }
          var myDate = new Date(year,month,0);
          // 本月第一天
          var startDate = new Date(year+'-'+month+'-01 00:00:00'); 
          picker.$emit('pick', [startDate, now]);

      }
  }, ],
    onPick: ({ maxDate, minDate }) => {
        this.pickerMinDate = minDate.getTime()
        if (maxDate) {
            this.pickerMinDate = ''
        }
    },
    disabledDate: (time) => {
        if (this.pickerMinDate !== '') {
            const day30 = (90 - 1) * 24 * 3600 * 1000
            let maxTime = this.pickerMinDate + day30
            if (maxTime > new Date()) {
                maxTime = new Date()
                return time.getTime() > maxTime || time.getTime() < this.pickerMinDate - day30
            } else {
                return maxTime < time.getTime() || time.getTime() < this.pickerMinDate - day30
            }
        }
        return time.getTime() > Date.now()
    },
  }

}
