import request from '@/plugin/axios'

// 列表
export function list(params) {
  return request({
    url: '/sys/role/list',
    method: 'get',
    params
  })
}
// 选择列表
export function select(params) {
  return request({
    url: '/sys/role/select',
    method: 'get',
    params
  })
}

// 保存
export function save(data) {
  return request({
    url: '/sys/role/save',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}

// 详细
export function info(params) {
  return request({
    url: '/sys/role/info/'+params.id,
    method: 'get',
  })
}


// 删除
export function deleted(data) {
  return request({
    url: '/sys/role/delete',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}

// 复制
export function copy(data) {
  return request({
    url: '/sys/role/copy',
    method: 'post',
    headers:{
      'Accept-Language':'zh',
      'Content-Type': 'application/json'
    },
    data
  })
}