<template>
  <el-drawer
    append-to-body
    @opened="openHandle"
    :title="'批量授权'"
    class="addEdit" :visible.sync="drawer" size="600px">
       <div style="margin:0 15px 15px 15px;">
               <div  class="card-content">
                 <div class="flex-header">
                   <div class="flex-left">
                      <SelectShop  @select="(val)=>{ selectShops(val) } "  :clearable="true"  ref="refSelectShop"  />
                      <span style="margin-left:10px;margin-top:2px;color:#2CC2C2">已选中{{new_selectionCompanyGoodsList.length}}</span>
                   </div>
                   <div class="flex-right">
                      <!-- <el-form-item> -->
                        <el-button type="primary" @click="handelTransferGoods(null)" > 保存</el-button>
                      <!-- </el-form-item -->
                   </div>
                 </div>
                <el-table
                    border
                    highlight-current-row
                    :height="tableHeight"
                    :max-height="tableHeight"
                    ref="companyGoodsTable"
                    :data="datalist"
                    tooltip-effect="dark"
                   @selection-change="handleSelectionCompanyChange">
                    <!-- <el-table-column  type="index" align="center" width="50" />  @selection-change="handleSelectionCompanyChange"-->
                    <el-table-column type="selection" width="55" prop="selection"/>
                    <el-table-column label="名称"  prop="fullName" />
                    <el-table-column label="工号"  prop="empNo" />
                    <el-table-column label="角色名称"  prop="roleName" />
                </el-table>
               </div>
       </div>
  </el-drawer>
</template>
<script>
  import * as apiUser from '@/api/sys/sysUser'
  import SelectShop from '_c/SelectShop'
  import * as apis from '@/api/sys/sysUser';
  import {treeDataTranslate} from '@/utils';
  export default {
    name: 'transferGoods',
    props: ['value'],
    inject:["reload"],
    components: {SelectShop},
    data() {
      return {
        drawer: false,
        tableHeight: window.innerHeight - 320,
        companyGoodsList: [],
        selectionCompanyGoodsList: [],
        selectionShopGoodsList: [],
        currentShop: {},
        searchForm:{},
        datalist:[],
        page: {
        page: 1,
        limit: 99999,
      },
      totalCount: 0,
      companyGoodsListId:[],
      datalistId:[],
      shopId:[],
      new_selectionCompanyGoodsList:[],
      }
    },
    watch: {
      value (val) {
        this.drawer = val
      },
      drawer (val) {
        this.$emit('input', val)
      }
    },
    mounted () {
      window.addEventListener('resize', () => { //  resize:当调整浏览器窗口的大小时触发事件
        this.tableHeight = window.innerHeight - 320
      })
      this.getList()
    },
    methods: {
      cloHandle(){
        this.reload();
      },
       handleSelectionCompanyChange(val) {
        this.selectionCompanyGoodsList = val.map(item=>{
          // this.$set(item,'selection',true)
            item.selection=true
          return item.id;
        })
        this.new_selectionCompanyGoodsList = [...new Set(this.selectionCompanyGoodsList)];
      },
      // 获取列表
      selectShops(val) {

        this.shopId=val
        let  params =  {shopId:val};
        this.getCompanyGoodsList(params);
        return val;
      },
      openHandle() {
        this.companyGoodsList =  []
        this.selectionCompanyGoodsList =  []
        this.selectionShopGoodsList =  []
        this.currentShop = {};


      },
      handelTransferGoods(val) {
        const param = {
          userIds: this.selectionCompanyGoodsList,
          shopId:this.shopId
        }
        apiUser.batchAuthorize(param).then(res => {
            if (res && res.code === 200) {
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg);
            }
        });
      },
      getList(reloadQuery) {
       if (reloadQuery) {
         this.page.page=1
        }
      this.loading = true;
      let params = this.$http.adornParams({
        ...this.page,
      });
      apis.list(params).then(res => {
        this.loading = false;
        this.totalCount = res.page.totalCount;
        this.datalist = treeDataTranslate(res.page.list);

        this.datalist.forEach(item=>{
          this.datalistId=item.id
          this.$set(item,'selection',false)
        })
        this.loading = false;
      });
    },
      getCompanyGoodsList(param) {
       apiUser.listSimple(param).then(res => {
          this.companyGoodsList = res.list.slice();
          this.companyGoodsList.forEach(item=>{
            this.datalist.forEach(i=>{
              if(i.id==item.id){
                this.$set(i,'selection',true)
                if(i.selection==true){
                   this.$refs.companyGoodsTable.toggleRowSelection(i);
                   this.selectionCompanyGoodsList.push(i.id)

                }else{
                    this.$refs.companyGoodsTable.clearSelection();
                }

              }
            })
          })

        });
      },
    },
  };
</script>



<style scoped>
 >>>.el-drawer__header{
    margin-bottom:15px !important
}
  >>> .flex-header{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }
  >>> .flex-left{
    width: 50%;
    display: flex;
    justify-content:flex-start;
  }
  >>> .flex-right{
    width: 50%;
    display: flex;
    justify-content:flex-end;
  }

  /deep/ .el-form{
    padding: 0;
  }
  /deep/ .el-form-item{
    margin-bottom:0;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/sys/sysUser/reasonManage.vue"
}
</vue-filename-injector>
