import store from '@/store'
import axios from 'axios'
import router from '@/router'
import merge from 'lodash/merge'

import {
  Message
} from 'element-ui'
import util from '@/libs/util'
import { objToFormData } from '@/utils'
import {console} from "vuedraggable/src/util/helper";
const qs = require('qs')
var count = 0
// 创建一个错误
function errorCreate(msg,showError) {
  const error = new Error(msg);
  errorLog(error,showError)
  //throw error
}

// 记录和显示错误
function errorLog(error,showError) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: error.message,
    type: 'danger',
    meta: {
      error
    }
  })
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(error)
  }
  if (count >= 1) {
    return
  } else {
    count++
  }
  // 显示提示
  if(showError){
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000,
      onClose() {
        count = 0
      }
    })
  }

}


axios.defaults.baseURL =  process.env.VUE_APP_API;
axios.defaults.timeout = 1000 * 60;

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const timeOut = config.headers['timeout'];
    const stringify = config.headers['stringify'];
    /*
    if(timeOut){
      //console.log("timeOut",timeOut )
      axios.defaults.timeout = timeOut * 1000;
    }
    */
    // 在请求发送之前做一些处理

    const token = util.cookies.get('token')
    const brandId = util.cookies.get('brandId')
    config.headers['token'] = token
    config.headers['brandId'] = brandId
    if (config.method === 'post' || config.method === 'POST' || config.method === 'put' || config.method === 'PUT') {
      if (!config.headers['Content-Type']) {
        // config.data = {
        //   brandId: brandId,
        //   ...config.data
        // }
        if (config.data instanceof FormData) {
          // config.data.append('brandId',brandId)
          config.headers['Content-Type'] = 'multipart/form-data; charset=UTF-8'
        } else {

          if (!config.data) {
            config.data = {}
          }
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
          var dd = qs.stringify(config.data)
          config.data = dd
        }

      }
    } else if (config.method === 'get' || config.method === 'GET') {
      // config.params = {
      //   brandId: brandId,
      //   ...config.params
      // }
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      if(stringify){
        config.paramsSerializer = function (params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        }
      }
    }
    return config
  },
  error => {
    // 发送失败
    Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const {
      code
    } = dataAxios
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (parseInt(code)) {
        case 200:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios
        case 401:
          // 重新登录
          // 登录过期 清除token跳转到登录界面
          util.cookies.remove('token')
          router.replace({
            name: 'login'
          })
          errorCreate(`请先登录`,true)
          return dataAxios
        default:
          errorCreate(`${dataAxios.msg}`,false)
          // 不是正确的 code
          return dataAxios
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          error.message = '未授权，请登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    }
    errorLog(error,true)
    return Promise.reject(error)
  }
)

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
axios.adornParams = (params = {}, openDefultParams = true) => {
  let defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
axios.adornParamsToken = (params) => {
  params.token = util.cookies.get('token')
  let defaults = {
    't': new Date().getTime()
  }
  const param = qs.stringify(merge(defaults, params) );
  return param;
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
axios.adornParamsQs = (params) => {
  let defaults = {
    't': new Date().getTime()
  }
  const param = qs.stringify(merge(defaults, params), { arrayFormat: 'indices' } );
  return param;
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
axios.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  let defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
axios.adornUrl = (actionName) => {
  return process.env.VUE_APP_API + actionName
}


export default axios
