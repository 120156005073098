import request from '@/plugin/axios'

// 列表
export function queryPage(params) {
  return request({
    url: '/bms/bmsBrand/queryPage',
    method: 'get',
    params
  })
}

// 选项
export function listOptions(params) {
  return request({
    url: '/bms/bmsBrand/listOptions',
    method: 'get',
    params
  })
}

// 获取用户可授权品牌门店列表
export function getAuthShopList(params) {
  return request({
    url: '/bms/bmsBrand/getAuthShopList',
    method: 'get',
    params
  })
}

// 保存
export function save(data) {
  let formJson = JSON.stringify(data)
  return request({
    url: `/bms/bmsBrand/${!data.id ? 'add' : 'update'}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: formJson
  })
}

// 详情
export function info(params) {
  return request({
    url: '/bms/bmsBrand/info/' + params.id,
    method: 'get'
  })
}

// 删除
export function deleted(data) {
  return request({
    url: '/bms/bmsBrand/deleted',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}


