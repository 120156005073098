import request from '@/plugin/axios'

// 保存
export function clearCache(data) {
  return request({
    url: '/sys/sysCommon/clearCache',
    method: 'post',
    headers:{
      'Content-Type': 'application/json'
    },
    data
  })
}
