<!--
 * @Description: sysDictItemSelect
 * @Author: juguangxing
 * @Date: 2020年02月20日16:27:16
 * @LastEditTime:
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-select v-model="selectKey"  :style="styles" :size="size" :multiple="multiple"
             :collapse-tags = "collapseTags"
             :clearable="clearable" @change="onSelect"  :placeholder="placeholder">
    <el-option
      v-for="item in dataList"
      :key="item.key"
      :label="item.label"
      :disabled="item.disabled"
      :value="item.key">
    </el-option>
  </el-select>
</template>
<script>
import * as api from '@/api/sys/sysDictItem'
export default {
  name: 'sysDictItemSelect',
  props: {
    value: {
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    styles: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    allOption: {
      type: Boolean,
      default: false
    },
    dictKey: String,
    lang: String,
    filter: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      selectKey:'',
      queryParam:{
        dictKey:'',
        lang:''
      },
      dataList:[],
    }
  },
  watch: {
    value: {
      handler(val){
        if(!this.multiple){
          if (typeof(val) == "number"  && val===0)
          {
            val = "0";
          }
          this.selectKey = val?val.toString():'';
        }else{
          this.selectKey = val;
        }

      },
      immediate: true
    },
    filter: {
      handler(filters) {
        if (filters.length > 0) {
          for(let i =0 ; i < this.dataList.length;i++ ){
              let dataIn = filters.find( item => this.dataList[i].key === item);
              if(dataIn){
                this.dataList[i].disabled = true;
              }
          }
          //强制刷新
          this.dataList = this.dataList.slice()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    onSelect(val){
      let item  = this.dataList.find( item => item.key === val)
      this.$emit('select',val,item, this.dataList )
    },
    setSelectList(list){
      this.$emit('setSelectList',list)
    },
    // 获取列表
    getList () {
      const queryParam = {
        lang:this.lang,
        dictKey:this.dictKey,
      }
      api.select(queryParam).then(res => {
        if( res && res.code === 200 && res.list) {
          if(this.allOption){
            this.dataList = [{
              key:'0',
              label:'全部',
            }];
          }
          this.dataList =  this.dataList.concat(res.list)
          this.setSelectList(this.dataList);
        }
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped>

</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/SelectDict/index.vue"
}
</vue-filename-injector>
