<template>
  <el-container>
    <el-main>
      <el-form class="addEdit" label-width="0px" :model="dataForm" ref="dataForm">
        <el-divider content-position="left">接单提成设置</el-divider>
        <el-row>
          <el-col :span="20">
            <el-checkbox v-model="dataForm.commissionType" :true-label="0" :false-label="1"> 启用接单提成
            </el-checkbox>
          </el-col>
        </el-row>
        <el-form-item v-if="dataForm.commissionType===0">
          <el-col :span="4">
            <el-radio v-model="dataForm.calculationType" :label="0">按消费额百分百比 ×</el-radio>
          </el-col>
          <el-col :span="8">
            <el-input v-model="dataForm.percentage"></el-input>
            %
          </el-col>
        </el-form-item>
        <el-form-item v-if="dataForm.commissionType===0">
          <el-col :span="4">
            <el-radio v-model="dataForm.calculationType" :label="1">按订单数量</el-radio>
          </el-col>
          <el-col :span="8">
            <el-input v-model="dataForm.orderQty"></el-input>
            元/桌
          </el-col>
        </el-form-item>
        <el-form-item v-if="dataForm.commissionType===0">
          <el-col :span="4">
            <el-radio v-model="dataForm.calculationType" :label="2">按消费人数</el-radio>
          </el-col>
          <el-col :span="8">
            <el-input v-model="dataForm.consumers"></el-input>
            元/人
          </el-col>
        </el-form-item>

        <el-divider content-position="left">新客提成</el-divider>
        <el-row>
          <el-col :span="20">
            <el-checkbox v-model="dataForm.newCustomerType" :true-label="0" :false-label="1">
              启用新客提成(客户第一次在系统内注册并生成订单消费成功，则接单人新开客数量加1)
            </el-checkbox>
          </el-col>
        </el-row>
        <el-form-item v-if="dataForm.newCustomerType===0">
          <el-col :span="8">
            <el-input v-model="dataForm.newCustomerAmount"></el-input>
            元/人
          </el-col>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer>
      <el-button type="primary" @click="onSave()">保存</el-button>
      <el-button @click="cancel()">取消</el-button>
    </el-footer>
  </el-container>
</template>
<script>
import * as api from '@/api/bsm/bsmConfigCommission'

export default {
  name: 'bsmConfigCommissionForm',
  props: ['value'],
  data() {
    return {
      dataForm: {
        percentage: 0,
        orderQty: 0,
        consumers: 0,
        newCustomerAmount: 0,
        newCustomerType: 1,
        commissionType: 1
      },
      drawer: false,
    }
  },
  watch: {
    value(val) {
      this.drawer = val
    },
    drawer(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    init(userId) {
      this.dataForm.userId = userId
      if (this.dataForm.userId) {
        api.info(userId).then(res => {
          if (res && res.code === 200 && res.data) {
            this.dataForm = res.data
            if (null !== res.data) {
              if (res.data.calculationType === 0) {
                this.dataForm.percentage = res.data.commissionAmount
              } else if (res.data.calculationType === 1) {
                this.dataForm.orderQty = res.data.commissionAmount
              } else if (res.data.calculationType === 2) {
                this.dataForm.consumers = res.data.commissionAmount
              }
            }
          }
        })
      } else {
        this.dataForm = {
          commissionType: 1,
          calculationType: 0,
          commissionAmount: 0,
          newCustomerType: 1,
          newCustomerAmount: 0
        }
      }
    },
    //鼠标单击的事件
    onSave() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          delete this.dataForm.createTime;
          delete this.dataForm.createBy;
          delete this.dataForm.updateTime;
          delete this.dataForm.updateBy;
          delete this.dataForm.deleted;
          delete this.dataForm.sort;
          if (this.dataForm.calculationType === 0) {
            this.dataForm.commissionAmount = this.dataForm.percentage;
          } else if (this.dataForm.calculationType === 1) {
            this.dataForm.commissionAmount = this.dataForm.orderQty;
          } else if (this.dataForm.calculationType === 2) {
            this.dataForm.commissionAmount = this.dataForm.consumers;
          }
          api.save(this.dataForm).then(res => {
            if (res && res.code === 200) {
              this.$message.success('保存成功')
              this.cancel();
            } else {
              this.$message.error(data.msg);
            }
          }).catch(() => {
            this.$message.error('保存失败')
          })
        }
      })
    },
    cancel() {
      this.$emit('cancelEvent');
    }
  }
}
</script>
<style scoped>
.el-footer {
  text-align: center;
}

>>> .el-input__inner {
  line-height: 30px;
  height: 30px;
  margin-right: 10px;
}

>>> .el-input {
  width: 80px;
  margin-right: 5px;
}

>>> .el-form-item {
  margin-bottom: 0;
  margin-left: 30px;
  margin-top: 10px;
}
</style>






<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/bsm/bsmConfigCommission/addOrUpdate.vue"
}
</vue-filename-injector>
