<template>
  <D2Container class="main-page">
    <div slot="header" class="between-header">
      <el-form :inline="true" :model="searchForm">
        <el-form-item style="margin-bottom: 10px">
          <el-input suffix-icon="el-icon-search" v-model="searchForm.fullName" placeholder="请输入姓名" clearable/>
        </el-form-item>
        <el-form-item style="margin-bottom: 10px">
          <el-input suffix-icon="el-icon-search" v-model="searchForm.empNo" placeholder="请输入工号" clearable/>
        </el-form-item>
        <el-form-item style="margin-bottom: 10px">
          <SelectSysDept v-model="searchForm.sysDeptId" @select="(val)=>{this.onselectDept(val)} "
                           ref="selectysDept"/>
        </el-form-item>
        <el-form-item style="margin-bottom: 10px">
          <el-select @change="onselectRole(searchForm.roleId)" v-model="searchForm.roleId" placeholder="请选择角色"
                     clearable>
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-bottom: 10px">
          <SelectShop v-model="searchForm.shopId" @select="(val)=>{ this.onSelectShop(val) } " ref="refSelectShop"
                       :isAll="true"/>
        </el-form-item>

        <el-form-item style="margin-bottom: 10px">

          <el-button type="primary" icon="el-icon-search" @click="()=>getList(true)">查询</el-button>
          <el-button type="primary" @click="openViewTabs(null,'new')" v-if="isAuth('sys:sysUser:add')" >+ 新增
          </el-button>

        </el-form-item>
         <el-form-item >
          <el-button type="primary" @click="authShopGoods" v-if="isAuth('bms:bmsReason:add')" >批量授权</el-button>
        </el-form-item>
        <!-- <el-form-item style="margin-bottom: 0">
          <el-input suffix-icon="el-icon-search" v-editor="searchForm.empNo" placeholder="请输入状态" clearable/>
        </el-form-item>-->
      </el-form>
    </div>


    <el-table :data="dataList"  style="width: 100%"
    height="100%" v-loading="loading" border fit row-key="id" v-if="isAuth('sys:sysUser:list')"   :row-class-name="tableRowClassName">
      <el-table-column
        type="index"
        :fixed="true"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column show-overflow-tooltip v-for="(item,i) in columns"
                       :key="i"
                       :prop="item.key"
                       :label="item.title"
                       :min-width="item.minWidth"
                       :fixed="item.fixedStatus==1"
                       :sortable="item.sortStatus==1">
        <template slot-scope="scope">
          <div  :class="'tableHeader_'+item.alignType">
            <div v-if="item.key==='sex'">
              <el-tag v-if=" scope.row.sex === 1  ">男</el-tag>
              <el-tag v-else-if="scope.row.sex === 0">女</el-tag>
              <el-tag v-else type="danger">未设置</el-tag>
            </div>
            <div v-else-if="item.key==='userType'">
              <el-tag v-if="scope.row.userType === 0">普通管理员</el-tag>
              <el-tag v-else type="danger">超级管理员</el-tag>
            </div>

            <el-image
              v-else-if="item.fieldsType==='pic'"
              style="width: 25px; height: 25px"
              :src="scope.row[scope.column.property]"
              :preview-src-list="[scope.row[scope.column.property]]"
              :fit="'contain'">
              <div slot="error" class="image-slot">无</div>
            </el-image>

            <div v-else-if="item.key==='status'" width="60">
              <el-tag v-if="scope.row.status === 0" type="danger">禁用</el-tag>
              <el-tag v-else>正常</el-tag>
            </div>
            <el-button v-else-if="item.key=='userName'" type="text" @click="openViewTabs(scope.row.id,'update')">
              {{ scope.row[scope.column.property] }}
            </el-button>
            <span v-else>{{ scope.row[scope.column.property] }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120" align="center">
        <template slot="header">
          <el-button type="text" icon="el-icon-view" @click="()=>this.viewTableColumn=true">列表显示</el-button>
        </template>
        <template slot-scope="scope">
          <el-popover placement="bottom" trigger="hover">
            <div class="dis-f">
              <el-button type="warning" class="mar-b" @click.stop="handleRepassword(scope.row.id)"
                         v-if="isAuth('sys:sysUser:rePassword')">重置密码
              </el-button>
              <el-button type="primary" class="mar-b"  @click.stop="openViewTabs(scope.row.id,'update')"
                         v-if="isAuth('sys:sysUser:update')" >编辑
              </el-button>
              <el-button type="danger" class="mar-b" @click.stop="handleDelete(scope.row)"
                         v-if="isAuth('sys:sysUser:delete')">删除
              </el-button>
            </div>
            <span slot="reference" class="el-dropdown-link">
              <i class="el-icon-s-operation"/>
            </span>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" style="margin-top: 10px;">
      <el-pagination background @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page.page"
                     :page-sizes="this.$pageSizes"
                     :page-size="page.limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>
    <reasonManage v-model="drawerTransferGoods"  ref="transferGoods"  />
    <open-tab v-model="tabDrawer" ref="tabDrawer" :userId="userId" :formType="formType"  @refParent="reloadPage" />
    <TableColumnSet v-model="viewTableColumn" ref="fieldSet" :moduleKey="'sysUser'" :fieldsSource="'column'"
                      @submit="getList" @refresh="(val)=>this.columns = val "/>

  </D2Container>
</template>

<script>
import * as api from '@/api/sys/sysUser';
import * as apiRole from '@/api/sys/sysRole';
import {treeDataTranslate} from '@/utils';
import SelectSysDept from '_c/SelectSysDept';
import TableColumnSet from '_c/TableColumnSet';
import SelectShop from '_c/SelectShop'
import openTab from './openTab'
import {mapMutations} from 'vuex'
import store from "../../../store/index"
  import reasonManage from './reasonManage'
export default {
  name: 'sysUser',
  components: {openTab, TableColumnSet, SelectSysDept, SelectShop,reasonManage},
  // inject:["reload"],
  data() {
    return {
      drawerTransferGoods: false,
      viewTableColumn: false,
      searchSetDialog: false,
      tabDrawer: false,
      columns: [],
      drawer: false,
      drawerForm: false,
      searchForm: {},
      dataList: [],
      roleList: [],
      page: {
        page: 1,
        limit: 50,
      },
      totalCount: 0,
      loading: false,
      formType: 'new',
      userId: '',
      aa:{}
    };
  },

  mounted() {
    this.$refs.fieldSet.getColumns();
    this.getList()
    this.getRoleList();
  },
  methods: {
    authShopGoods(){
      this.drawerTransferGoods = true
    },

    getRoleList() {
      apiRole.select().then(res => {
        this.roleList = res && res.code === 200 ? res.list : []
      });
    },
    // 获取列表
    getList(reloadQuery) {
       if (reloadQuery) {
         this.page.page=1
        }
      this.loading = true;
      let params = this.$http.adornParams({
        ...this.page,
        ...this.searchForm,
      });
      api.list(params).then(res => {
        this.loading = false;
        this.totalCount = res.page.totalCount;
        this.dataList = treeDataTranslate(res.page.list);
      }).catch(() => {
        this.loading = false;
      });
    },
    // 重置密码
    handleRepassword(id) {
      this.loading = true;
      api.rePassword({id: id}).then(res => {
        if (res && res.code === 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      })
    },
    handleDelete(raw) {
      this.$confirm('确认要删除么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.deleted([raw.id]).then(res => {
          this.$message.success('删除成功');
          this.getList(true);
        });
      });
    },
    handleSizeChange(size) {
      this.page = {limit: size, page: 1};
      this.getList();
    },
    handleCurrentChange(cur) {
      this.page.page = cur;
      this.getList();
    },
    onSelectShop(item) {
      this.searchForm.shopId = item;
      this.getList();
    },
    onselectDept(item) {
      this.searchForm.sysDeptId = item;
      this.getList();
    },
    onselectRole(item) {
      this.searchForm.roleId = item;
      this.getList();
    },
    openViewTabs(id, formType) {

      this.tabDrawer = true;
      this.userId = id;
      this.formType = formType;
      this.$nextTick(() => {
        this.$refs.tabDrawer.init(id)
      })
    },
    reloadPage(){
      this.tabDrawer = false;
      // this.reload()
    },
    // 列表隔行换色的方法
    tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
            return 'warning-row';
          }else{
            return 'success-row';
          }
    },
  },
};
</script>
<style scoped>
>>> .cell .el-button--text {
  padding: 0;
}

>>> .el-pagination {
  text-align: right;
}

.dis-f {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mar-b {
  width: 100%;
  margin-bottom: 5px;
  margin-left: 0;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/sys/sysUser/index.vue"
}
</vue-filename-injector>
