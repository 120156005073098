import request from '@/plugin/axios'
// 列表
export function queryPage(params) {
  return request({
    url: '/bms/bmsShop/queryPage',
    method: 'get',
    params
  })
}

// 列表
export function select(params) {
  return request({
    url: '/bms/bmsShop/select',
    method: 'get',
    params
  })
}


// 排序
export function updateSort(params,type) {
  return request({
    url: `/bms/bmsShop/${type=='up' ? 'updateSortUp' : 'updateSortDown'}`,
    method: 'get',
    params
  })
}


// 保存
export function save(data) {
  return request({
    url: '/bms/bmsShop/add',
    method: 'post',
    headers:{
      'Content-Type': 'application/json'
    },
    data
  })
}

// 保存
export function removeTestData(data) {
  return request({
    url: `/bms/bmsShop/removeTestData`,
    method: 'post',
    data
  })
}

// 详情
export function info(params) {
  return request({
    url: '/bms/bmsShop/info/' + params.id,
    method: 'get'
  })
}

// 删除
export function deleted(data) {
  return request({
    url: '/bms/bmsShop/deleted',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}

// 生成门店排队二维码
export function createQueueQrcode(id) {
  return request({
    url: '/bms/bmsShop/createQueue/qrcode/' + id,
    method: 'get',
    responseType: 'arraybuffer'
  })
}

// 生成门店排队二维码
export function createMiniAppRechargeQrCode(id) {
  return request({
    url: '/bms/bmsShop/createMiniAppRechargeQrCode/' + id,
    method: 'get',
    responseType: 'arraybuffer'
  })
}

