<!--
 * @Description: SelectShop
 * @Author: juguangxing
 * @Date: 2020年02月20日16:27:16
 * @LastEditTime:
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-select v-model="selectKey" :multiple="multiple" filterable :clearable="clearable"  @change="onSelect" :placeholder="placeholder">
      <el-option
        v-for="item in dataList"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
  </el-select>
</template>
<script>
import * as api from '@/api/bms/bmsShop';
export default {
  name: 'selectShop',
  props: {
    value: String,
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请选择门店'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allName: {
      type: String,
      default: '总部'
    },
    isAll: {
      type: Boolean,
      default: false
    },
  },


  data () {
    return {
      selectKey:'',
      dataList:[],
    }
  },
  watch: {
    value: {
      handler(val){
        if(this.multiple && val){
          val = val.split(',')
        }
        this.selectKey = val
      },
      immediate: true
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    onSelect(item){
      let val = item
      if(this.multiple){
        val = item.join(",")
      }
      this.$emit('select',val);
      this.$emit('selectOne',val);

    },
    // 获取列表
    getList () {
      api.select().then(res => {
        this.dataList = res && res.code === 200 ? res.list : []
        if(this.dataList.length > 0 ){
          if(this.isAll){
            const allObj = {id:'0',name:this.allName};
            this.dataList = [allObj].concat(this.dataList);
          }
        }
      })
    }
  }
}
</script>
<style scoped>

</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/SelectShop/index.vue"
}
</vue-filename-injector>
