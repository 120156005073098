<!--
 * @Description: In User Settings Edit
 * @Author: 具光星
 * @Date: 2020年01月27日16:31:41
 * @LastEditTime: 2019-10-23 15:13:03
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-dialog @opened="onOpened" @closed="onCloseClearSelection" class="table-columns-set" :append-to-body="true"  :title="fieldsSource==='column'?'列表显示设置':'筛选条件设置'"
             :visible.sync="visible"
             width="850px">

  <el-button type="primary"  @click="retrospectives" class="table-columns-button">恢复默认值</el-button>
    <el-table :data="userColumnsList"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              v-loading="loading"
              row-key="moduleFieldsId"
              ref="refTableColumnSet"  max-height="400" border >
      <el-table-column
        type="selection"
        width="50" />
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="fieldsName"
        label="字段名称"
        align="center"
        width="180px">
      </el-table-column>
      <el-table-column
        prop="minWidth"
        label="最小宽度(px)"
        width="80px">
        <template slot-scope="scope">
          <el-input v-model="scope.row.minWidth" />
        </template>
      </el-table-column>
      <el-table-column
        prop="alignType"
        label="对齐方式"
        width="110px">
        <template slot-scope="scope">
          <SelectDict v-model="scope.row.alignType" :clearable="false" placeholder="对齐方式"  @select="(val)=> scope.row.alignType = val "  :dictKey="'sysModuleFieldsAlignType'" :lang="'zh'"  />
        </template>
      </el-table-column>
      <el-table-column
        prop="sort"
        label="排序"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        label="操作排序"
        align="center"
        width="120">
        <template slot-scope="scope">
          <el-button v-if="scope.row.id" icon="el-icon-sort-up" @click="updateSort(scope.row.id,scope.$index,scope.$index-1)" circle ></el-button>
          <el-button  v-if="scope.row.id" icon="el-icon-sort-down" @click="updateSort(scope.row.id,scope.$index,scope.$index+1)" circle></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button style="width:72px;padding:9px 0;" @click="visible = false">关 闭</el-button>
      <el-button style="width:72px;padding:9px 0;" type="primary" @click="onSave" :loading="loading">保 存</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '@/api/sys/sysUserModuleFields'
import SelectDict  from '_c/SelectDict'
import Bus from '../../utils/bus'
export default {
  name: 'TableColumnSet',
  props: {
    value: Boolean,
    moduleKey: String,
    fieldsSource: String,
  },
  components: {  SelectDict},
  data () {
    return {
      visible: false,
      moduleKeyId: '',
      fieldsSourced: '',
      columns: [],
      userColumnsList: [],
      selectUserColumnsList: [],
      multipleSelection: [],
      loading: false,
      gmsGoods:'',
    }
  },
  watch: {
    value (val) {
      this.visible = val
    },
    moduleKey (val) {
      this.moduleKeyId = valconsole.log
    },
    fieldsSource (val) {
      this.fieldsSourced = val
    },
    visible (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
  Bus.$on('newWidth',newWidth=>{
    this.newWidthdata=newWidth
  })

  Bus.$on('event',event=>{
    this.userColumnsList.forEach(val => {
       if(event.label==val.fieldsName){
        val.minWidth=this.newWidthdata
      }
    });
  })
  },
   methods: {
     retrospectives(){
      let params  = JSON.stringify({
        moduleKey:this.moduleKey
      })
      api.retrospective(params).then(res => {
        this.getColumns(true)
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
      let saveList = this.multipleSelection.filter(item => {
        return item.id === '';
      }).slice()
    },
     updateSort(id, currentIndex,convertIndex) {
       if (convertIndex < 0) {
         this.$message.error("已经到最顶部了:"+convertIndex+":"+currentIndex);
       }
       if (convertIndex >= this.userColumnsList.length) {
         this.$message.error("已经到最底部了:"+convertIndex+":"+this.userColumnsList.length);
       }
       // 当前排序号
       let currentId = this.userColumnsList[currentIndex].id;
       // 当前排序号
       let currentSort = this.userColumnsList[currentIndex].sort;
       // 替换主键id
       let convertId= this.userColumnsList[convertIndex].id;
       // 替换排序号
       let convertSort= this.userColumnsList[convertIndex].sort;
       this.loading = true
       api.updateSort({
         'currentId': currentId,
         'currentSort': currentSort,
         'convertId': convertId,
         'convertSort': convertSort,
         'moduleKey':  this.moduleKey,
         'fieldsSource':  this.fieldsSource
       }).then(res => {
        if (res && res.code === 200) {
          this.loading = false
          this.getColumns(true)
          this.$message({
            message: '更新成功',
            type: 'success'
          })
        } else {
          this.loading = false
          this.$message.error(res.msg);
        }
      })
    },
    sortByKey(ary, key) {
        return ary.sort(function (a, b) {
          let x = a[key]
          let y = b[key]
          return ((x < y) ? -1 : (x > y) ? 1 : 0)
        })
    },
    onSave () {
      this.loading = true
      let jsonBody  = JSON.stringify({
        'moduleFieldsList':  this.multipleSelection,
        'moduleKey':  this.moduleKey,
        'fieldsSource':  this.fieldsSource
      })
      api.save(jsonBody).then(res => {
        this.loading = false
        this.$emit('submit')
        this.selectUserColumnsList = this.multipleSelection;
        this.columns = this.multipleSelection.map(item=>{
          return {
            title: item.fieldsName,
            key: item.fieldsKey,
            minWidth: item.minWidth,
            moduleKey: item.moduleKey,
            fieldsSource: item.fieldsSource,
            sortStatus: item.sortStatus,
            sort: item.sort,
            fixedStatus: item.fixedStatus,
            alignType: item.alignType,
            fieldsType: item.fieldsType,
            moduleFieldsId: item.moduleFieldsId,
          };
        })
        if (res && res.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$emit('refresh',this.columns)
           this.getColumns(true)
        } else {
          this.loading = false
          this.$message.error('设置失败！' + res.msg);
        }
      })
    },
    onCloseClearSelection () {
      this.$refs.refTableColumnSet.clearSelection();
    },
    onOpened () {
      this.onSetTableColumnChecked();
      if (this.userColumnsList.length > 0) {
        let userColumnsCount = 0;
        this.userColumnsList.slice().forEach(row => {
          if (row.id) {
            userColumnsCount++;
          }
        })
        if (userColumnsCount < 1) {
          this.onSave();
        }
      }
    },
    onSetTableColumnChecked () {
      if (this.selectUserColumnsList.length > 0) {
        this.selectUserColumnsList.slice().forEach(row => {
          this.$refs.refTableColumnSet.toggleRowSelection(row, true);
         })
      }else{
        this.$refs.refTableColumnSet.clearSelection();
      }
    },
      // 获取到自定义显示的数据
    getColumns (columnChecked) {
        this.loading = true;
        api.select({ moduleKey:this.moduleKey,fieldsSource:this.fieldsSource }).then(res => {
          if(res.list && res.list.length > 0){
            this.userColumnsList = res.list.slice();
            this.selectUserColumnsList = res.list.filter(item => {
              return item.status === 1;
            }).slice()
            this.columns = this.selectUserColumnsList.map(item=>{
              return {
                title: item.fieldsName,
                key: item.fieldsKey,
                moduleKey: item.moduleKey,
                fieldsSource: item.fieldsSource,
                minWidth: item.minWidth,
                alignType: item.alignType,
                fixedStatus: item.fixedStatus,
                sortStatus: item.sortStatus,
                sort: item.sort,
                fieldsType: item.fieldsType,
                moduleFieldsId: item.moduleFieldsId,
              };
            })
          }
          if(columnChecked){
            this.$nextTick(() => {
              this.onSetTableColumnChecked()
            })
          }
          this.loading = false;
          this.$emit('refresh',this.columns)
        })
    }
  }
}
</script>
<style lang="css">
.el-dialog__header{
 padding-bottom: 0 !important;
 margin-bottom: 20px;
}
.table-columns-button{
  position: absolute;
  margin-top:-40px;
  margin-bottom: 10px !important;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/TableColumnSet/index.vue"
}
</vue-filename-injector>
