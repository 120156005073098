/**
 * Created by jgx on 2020/09/24.
 *
 * this.bgImg = {
 *     imgSrc: file.imgSrc,
 *     imgId: file.imgId,
 *     width: file.width,
 *     height: file.height
 * }
 *
 *
 */
export default class Page {
  constructor (page = {}) {
    this.status = page.status  || 1
    this.sort = page.sort  || 999
    this.bgImgUrl = page.bgImgUrl
    this.releaseStatus = page.releaseStatus || false
    this.menuImgUrl = page.menuImgUrl
    this.pageType = page.pageType  || 1
    this.pageName = page.pageName  || ''
    this.categoryId = page.categoryId
    this.categoryName = page.categoryName
    this.elements = page.elements || []
    this.multipleElements = page.multipleElements || []
   }
}
