<template>
  <div class="D2Container-ghost-bs">
    <div v-if="$slots.header" class="D2Container-ghost-bs__header" ref="header">
      <slot name="header"/>
    </div>
    <div class="D2Container-ghost-bs__body" ref="wrapper">
      <!-- https://github.com/d2-projects/d2-admin/issues/181 -->
      <div>
        <slot/>
      </div>
    </div>
    <div v-if="$slots.footer" class="D2Container-ghost-bs__footer" ref="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import bs from './mixins/bs'
export default {
  name: 'D2Container-card-bs',
  mixins: [
    bs
  ]
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/D2Container/components/D2ContainerGhostBs.vue"
}
</vue-filename-injector>
