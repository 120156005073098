import request from '@/plugin/axios'
// 列表
export function queryPage(params) {
	return request({
		url: '/bsm/bsmConfigCommission/queryPage',
		method: 'get',
		params
	})
}

// 选项
export function listOptions(params) {
	return request({
		url: '/bsm/bsmConfigCommission/listOptions',
		method: 'get',
		params
	})
}

// 保存
export function save(data) {
	let formJson  = JSON.stringify(data)
	return request({
		url: `/bsm/bsmConfigCommission/${!data.id ? 'add' : 'update'}`,
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		data:formJson
	})
}

// 详情
export function info(userId) {
	return request({
		url: '/bsm/bsmConfigCommission/info/' + userId,
		method: 'get'
	})
}

// 删除
export function deleted(data) {
	return request({
		url: '/bsm/bsmConfigCommission/deleted',
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'post',
		data
	})
}
