<template>
  <div style="border-left:1px solid #aaa">
    <span size="medium" class="company-name-text"  type="info">集团:{{userInfo.companyName}}</span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('d2admin/user', [
      'userInfo'
    ])
  },
  data(){
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .company-name-text {
    padding: 7px;
    font-size: 13px;
    margin-left: 10px;
    margin-right: 0px !important;
    color: #505460;
    background: #626E80 !important;
    border-radius: 4px 0 0 4px ;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-company/index.vue"
}
</vue-filename-injector>
