import * as types from './mutation-type'
import Page from '../modules/editor/Page'
import Theme from '../modules/editor/Theme'
import Element from '../modules/editor/Element'
import tools from '../../utils/jsonUtil'
import searchFormdata from '../../views/sys/sysUser/index.vue'

/**
 * 创建场景主题
 * @param commit
 */

export const createTheme = ({commit}, type) => {
  var theme = new Theme({type: type})
  commit(types.CREATE_THEME, theme)
  commit(types.SET_CUR_EDITOR_THEME, theme)
}

/**
 * 设置当前编辑的主题
 */
export const setEditorTheme = ({commit}, theme) => {
  var newTheme = new Theme(theme)
  commit(types.SET_CUR_EDITOR_THEME, newTheme)
}

/**
 * 设置当前正在编辑的页面
 * @param commit
 * @param page
 */
export const setEditorPage = ({commit}, page) => {
  commit(types.SET_CUR_EDITOR_PAGE, page)
}
/**
 * 设置当前正在编辑的页面
 * @param commit
 * @param page
 */
export const updateSort = ({commit}, indexObj) => {
  commit(types.UPDATE_SORT, indexObj)
}

/**
 * 给主题添加页面
 * @param commit
 */
export const addPage = ({commit}) => {
  let page = new Page()
  page.sort = page.sort + 1;
  commit(types.ADD_PAGE, page)
  commit(types.SET_CUR_EDITOR_PAGE, page)
}

/**
 * 添加页面元素
 */
export const addElement = ({commit, state}, data) => {
  commit(types.ADD_PIC_ELEMENT, new Element(data))
  var list = state.editorPage.elements
  var lastIndex = list.length - 1
  list[lastIndex]['zindex'] = lastIndex ?  parseInt(list[lastIndex - 1]['zindex']) + 1 : 1


  console.log("lastIndex", list[lastIndex]['zindex'])

  commit(types.SET_CUR_EDITOR_ELEMENT, state.editorPage.elements[lastIndex])
}




/**
 * 移除页面背景图片
 */
export const removePageBgImg = ({commit}) => {
  commit(types.REMOVE_PAGE_BG_IMG)
}

/**
 * 添加页面背景图片
 */
export const setPageBgImg = ({commit}, data) => {
  commit(types.SET_PAGE_BG_IMG,data)
}

/**
 * 设置菜品分类
 */
export const setPageCategory = ({commit}, data) => {
  commit(types.SET_PAGE_CATEGORY,data)
}

/**
 * 设置页面类型
 */
export const setPageType = ({commit}, data) => {
  commit(types.SET_PAGE_TYPE,data)
}

/**
 * 设置页面类型
 */
export const setPageName = ({commit}, data) => {
  commit(types.SET_PAGE_NAME,data)
}

/**
 * 设置上传主题
 */
export const setUploadingTheme  = ({commit}, data) => {
  commit(types.SET_UPLOADING_THEME,data)
}






/**
 * 保存图片
 * @param commit
 * @param data
 */
export const savePic = ({commit}, data) => {
  commit(types.PUSH_PIC_LIST, data)
}

/**
 * 保存视频
 * @param commit
 * @param data
 */
export const saveVideo = ({commit}, data) => {
  commit(types.PUSH_VIDEO_LIST, data)
}
/**
 * 清除背景
 * @param commit
 */
export const cleanBG = ({commit}) => {
  commit(types.CLEAN_BG)
}

export const cleanEle = ({commit}, ele) => {
  commit(types.CLEAN_ELE, ele)
}
/**
 * 复制页面
 * @param commit
 */
export const copyPage = ({commit}, data) => {
  let page = tools.vue2json(data)
  page.sort = page.sort + 1;
  page.pageName = page.pageName + "_副本";
  commit(types.ADD_PAGE, page)
}

/**
 * 删除页面
 * @param commit
 */
export const delPage = ({commit}, page) => {
  commit(types.DELETE_PAGE, page)
}


export const setEditorElement = ({commit}, element) => {
  commit(types.SET_CUR_EDITOR_ELEMENT, element)
}

// 删除元素
export const deleteElement = ({commit}, element) => {
  commit(types.DELETE_ELEMENT, element)
}

export const deleteSelectedElement = ({commit, state}) => {
  commit(types.DELETE_ELEMENT, state.editorElement)
}

export const playAnimate = ({state, commit, getters}) => {
  commit(types.PLAY_ANIMATE)
  let target = getters['editingElement'] || getters['editingPageElements'] || null
  let time = 0
  if (target instanceof Array) {
    target.forEach(v => {
      time = v['animatedName'] && (v['duration'] + v['delay']) > time ? (v['duration'] + v['delay']) : time
    })
  } else if (target instanceof Object) {
    time = (target['duration'] + target['delay'])
  }
  setTimeout(() => {
    commit(types.STOP_ANIMATE, target)
  }, time * 1000)
}

export const cleanPicList = ({commit}) => {
  commit(types.CLEAN_PIC_LIST)
}

export const sortElementsByZindex = ({commit}, location) => {
  commit(types.SORTELEMENTS_BY_ZINDEX, location)
}


