<template>
  <el-container>
    <el-main>
      <el-form :model="dataForm" ref="dataForm" label-position="right" label-width="160px"  v-loading="loading">
        <el-row :gutter="20">
          <el-col :span="11">
            <div class="leftCss">
            <el-form-item label="用户名：" prop="userName" :rules="[ { required: true, message: '用户名不能为空', trigger: 'blur' }]" maxlength="16">
              <el-input v-model="dataForm.userName" :disabled="formType!=='new'" placeholder="用户名">
                <template slot="append">用于后台管理登录</template>
              </el-input>
            </el-form-item>
            <el-form-item label="员工号：" prop="empNo" :rules="[ {validator: validateNumber, trigger: 'blur' }]">
              <el-input v-model="dataForm.empNo" :disabled="formType!=='new'" placeholder="员工号">
                <template slot="append">用于POS系统登录</template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="formType==='new' " label="密码：" prop="password"
                          :rules="[{required: true, validator: validatePassword, trigger: 'blur' }]">
              <el-input v-model="dataForm.password" type="password" placeholder="密码"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' " label="确认密码：" prop="comfirmPassword"
                          :rules="[{ required: true,validator: validateComfirmPassword, trigger: 'blur' }]">
              <el-input v-model="dataForm.comfirmPassword" type="password" placeholder="确认密码"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="姓名：" prop="fullName"
                          :rules="[ { required: true, message: '姓名不能为空', trigger: 'blur' }]">
              <el-input v-model="dataForm.fullName" placeholder="姓名"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="角色："
                          :rules="[ { required: true, message: '角色不能为空', trigger: 'blur' }]">
              <el-select v-model="role" placeholder="请选择角色" clearable style="width: 100%">
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="部门："
                          :rules="[ { required: true, message: '部门不能为空', trigger: 'blur' }]">
              <SelectSysDept style="width: 100%" v-model="dataForm.sysDeptId" @select="(val)=>{ this.dataForm.sysDeptId = val;} "
                             ref="refSelectSysDept"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="等级："
                          :rules="[ { required: true, message: '等级不能为空', trigger: 'blur' }]">
              <el-select v-model="dataForm.grade" placeholder="请选择" style="width: 100%">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="性别：" prop="sex">
              <el-radio-group v-model="dataForm.sex">
                <el-radio :label="0">女士</el-radio>
                <el-radio :label="1">男士</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="生日：" prop="birthday">
              <el-date-picker
                style="width: 100%"
                v-model="dataForm.birthday"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="手机号：" prop="mobile">
              <el-input v-model="dataForm.mobile" placeholder="手机号"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="邮箱：" prop="email">
              <el-input v-model="dataForm.email" placeholder="邮箱"/>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="状态：" prop="status"
                          :rules="[ { required: true, message: '状态不能为空', trigger: 'blur' }]">
              <el-radio-group v-model="dataForm.status">
                <el-radio :label="0">禁用</el-radio>
                <el-radio :label="1">正常</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="是否销售人：" prop="isSalesPerson">
              <el-switch
                v-model="dataForm.isSalesPerson"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="按授权门店显示会员：" prop="isAuthShopShowMember">
              <el-switch
                v-model="dataForm.isAuthShopShowMember"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="餐桌翻台率排名：" prop="reportDeskCountTop">
              <el-switch
                v-model="dataForm.reportDeskCountTop"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item v-if="formType==='new' ||  formType==='update' " label="餐桌消费排名：" prop="reportDeskAmountTop">
              <el-switch
                v-model="dataForm.reportDeskAmountTop"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                inactive-color="#ff4949"></el-switch>
            </el-form-item>

              <el-form-item align="right">
              <el-button @click="cancel()" size="medium">取消</el-button>
              <el-button type="primary" @click="onSave()" size="medium">保存</el-button>
            </el-form-item>
            </div>
          </el-col>
          <el-col :span="13">
            <div style="border:1px solid #f0f2f5;padding: 10px;max-height: 400px;">
              <span style="font-size: 14px">关联收银员</span>
              <el-form-item  v-model="dataForm.relationUserList" label-width="0">
                <el-table
                  border align="center"
                  max-height="364"
                  highlight-current-row
                  @selection-change="handleSelectionUserChange"
                  :data="userListSimple"
                  ref="refUserList"
                  style="width: 100%;">
                  <el-table-column
                    type="selection"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    label="用户名"
                    prop="userName">
                  </el-table-column>
                  <el-table-column
                    label="员工号"
                    prop="empNo">
                  </el-table-column>
                  <el-table-column
                    label="部门"
                    prop="deptName">
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
            <div style="border:1px solid #f0f2f5;padding: 10px;max-height: 400px;margin-top: 10px;">
              <span style="font-size: 14px">授权门店</span>
              <el-form-item  v-model="dataForm.shopIdList" label-width="0">
                <el-table
                  border align="center"
                  highlight-current-row
                  max-height="364"
                  @selection-change="handleSelectionShopChange"
                  :data="shopList"
                  ref="refShopList"
                  style="width: 100%;">
                  <el-table-column
                    type="selection"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    label="品牌"
                    prop="brandName">
                  </el-table-column>
                  <el-table-column
                    label="门店"
                    prop="name">
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>

            <!--            <el-transfer v-model="dataForm.relationUserList"-->
<!--                         :titles="['未选中列表', '选中列表']"-->
<!--                         :props="{key: 'id',label: 'name'}" :data="userListSimple"></el-transfer>-->
<!--            <RelationBrandShop v-model="dataForm.shopIdList" ref="refRelationBrandShop" @onChange="(val)=>{this.selectShopList=val}"  />-->

          </el-col>
        </el-row>

      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import SelectSysDept from '_c/SelectSysDept';
import * as api from '@/api/sys/sysUser';
import * as apiRole from '@/api/sys/sysRole';
import * as apiShop from '@/api/bms/bmsShop';
import {isEmail, isMobile} from '@/utils/validate';
import {objToFormData} from '@/utils';

export default {
  props: ['value', 'detail', 'formType'],
  components: {SelectSysDept},
  data() {
    return {
      validateNumber: (rule, value, callback) => {
        if (!/^[0-9]{1,15}$/.test(value)) {
          callback(new Error('编码只能输入1-15位以内数字'));
        } else {
          callback();
        }
      },
      validateMobile: (rule, value, callback) => {
        if (!isMobile(value)) {
          callback(new Error('手机号格式错误'));
        } else {
          callback();
        }
      },
      validateEmail: (rule, value, callback) => {
        if (!isEmail(value)) {
          callback(new Error('邮箱格式错误'));
        } else {
          callback();
        }
      },
      validateComfirmPassword: (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error('确认密码不能为空'));
        } else if (this.dataForm.password !== value) {
          callback(new Error('确认密码与密码输入不一致'));
        } else {
          callback();
        }
      },
      validatePassword: (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error('密码不能为空'));
        } else {
          callback();
        }
      },
      selectShopList: [],
      roleList: [],
      shopList:[],
      role: '',
      dataForm: {
        roleIdList: [],
        relationUserList:[],
      },
      options: [{
        value: 1,
        label: '一级以上'
      }, {
        value: 2,
        label: '二级以上'
      }, {
        value: 3,
        label: '三级以上'
      }, {
        value: 4,
        label: '四级以上'
      }, {
        value: 5,
        label: '五级以上'
      }, {
        value: 6,
        label: '六级以上'
      }, {
        value: 7,
        label: '七级以上'
      }, {
        value: 8,
        label: '八级以上'
      }, {
        value: 9,
        label: '九级以上'
      }, {
        value: 10,
        label: '十级以上'
      }],
      drawer: false,
      loading: false,
      userListSimple:[],
      multipleUserSelection: [],
      multipleShopSelection:[],
    }
  },
  watch: {
    value(val) {
      this.drawer = val;
    },
    drawer(val) {
      this.$emit('input', val);
    }
  },
  created() {
  },
  methods: {
    getRoleList() {
      apiRole.select().then(res => {
        this.roleList = res && res.code === 200 ? res.list : []
      });
    },
    init(id) {
      this.multipleUserSelection = [];
      this.multipleSelections = [];
      this.dataForm.relationUserList = [];
      this.dataForm.shopIdList = [];
      this.$refs['dataForm'].clearValidate()
      this.getRoleList();
      this.getShopList();
      this.getUserList();
      this.shopIdList = [];
      this.dataForm.id = id
      if (this.dataForm.id) {
        this.loading = true
        api.info(this.dataForm).then(res => {
          if (res && res.code === 200) {
            this.dataForm = res.user
            this.role = this.dataForm.roleIdList[0]
            if (this.dataForm.relationUserList.length > 0) {
              this.userListSimple.forEach(item=>{
                this.dataForm.relationUserList.forEach(i=>{
                  if (item.id == i){
                    this.$refs.refUserList.toggleRowSelection(item, true);
                  }
                })
              })
            }
            if(this.dataForm.shopIdList.length > 0){
              this.shopList.forEach(row =>{
                this.dataForm.shopIdList.slice().forEach(e => {
                  if (row.id == e){
                    this.$refs.refShopList.toggleRowSelection(row, true);
                  }
                })
              })

            }
          }
          this.loading = false
        })
      } else {
        this.dataForm = {
          birthday:'',
          email:'',
          grade:'',
          sysDeptId:'',
          comfirmPassword:'',
          userName:'',
          password:'',
          empNo:'',
          status: 1,
          isSalesPerson: 1,
          isAuthShopShowMember: 0,
          reportDeskCountTop: 1,
          reportDeskAmountTop: 1,
          relationUserList:[],
          roleIdList: [],
          shopIdList: [],
        }
      }
    },
    getUserList(){
      let params = {
        page: 1,
        limit: 999,
      }
      api.list(params).then(res =>{
        if (res && res.code === 200) {
          this.userListSimple = res.page.list
        }
      })
    },
    getShopList(){
      let params = {
        type: 1,
      }
      apiShop.select(params).then(res => {
        if(res && res.code === 200){
          this.shopList =res.list
        }
      })
    },
    handleClose() {
      this.$confirm('确定需要关闭表单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        this.$emit('refreshList')
        this.drawer = false
      })
    },
    handleSelectionUserChange(val){
      this.multipleUserSelection = val;
    },
    handleSelectionShopChange(val){
      this.multipleShopSelection = val;
    },
    //鼠标单击的事件
    onSave() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.dataForm.roleIdList = this.role;
          delete this.dataForm.imgFile;
          delete this.dataForm.createTime;
          delete this.dataForm.createBy;
          delete this.dataForm.updateTime;
          delete this.dataForm.updateBy;
          if (this.formType === 'update') {
            delete this.dataForm.password;
            delete this.dataForm.salt;
          }
          let relationUserList = [];
          this.multipleUserSelection.forEach(item=>{
            relationUserList.push(item.id)
          })
          let relationShopIdList = [];
          this.multipleShopSelection.forEach(item=>{
            relationShopIdList.push(item.id)
            this.dataForm.shopIdList.push(item.id)
          })
          this.dataForm.relationUserList = relationUserList;
          this.dataForm.shopIdList = relationShopIdList;
          let formData = objToFormData(this.dataForm)
          api.save(formData).then(res => {
            if (res && res.code === 200) {
              this.loading = false
              this.$message.success('保存成功')
              this.cancel();
            } else {
              this.loading = false
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    cancel(){
      this.$emit('cancelEvent');
    }
  }
}
</script>
<style scoped lang="scss">

> > > .transfer-title {
  margin-top: 0px;
  margin-bottom: 0px;
}
.el-main{
  padding: 0;
}

.leftCss{
  border-right:1px solid #f0f2f5;
  box-shadow: #EFEFEF;
  height: 100vh;
  padding-right: 15px;
}
</style>


<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/sys/sysUser/addOrUpdate.vue"
}
</vue-filename-injector>
